import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress"; // Import the CircularProgress component
import MKBox from "components/User/MKBox";
import Box from "@mui/material/Box";
import MKInput from "components/User/MKInput";
import TransparentBlogCard from "components/User/Cards/BlogCards/TransparentBlogCard";
import { getArticles, getUsers } from "request/api";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import DefaultFooter from "components/User/Footers/DefaultFooter";
import MKTypography from "components/User/MKTypography";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/User/images/bg-about-us.jpg";

// Default image URL
const defaultImage = "https://via.placeholder.com/300";

// Function to extract the first image URL from HTML content
const extractImageUrl = (htmlContent) => {
  const doc = new DOMParser().parseFromString(htmlContent, "text/html");
  const img = doc.querySelector("img");
  return img ? img.src : defaultImage;
};

// Function to remove image tags from HTML content
const removeImagesFromHtml = (htmlContent) => {
  const doc = new DOMParser().parseFromString(htmlContent, "text/html");
  const images = doc.querySelectorAll("img");
  images.forEach((img) => img.remove());
  let html = doc.body.innerHTML;
  html = html.replace(/(\s{2,}|\n{2,})/g, " ").trim();
  html = html.replace(/<p>\s*<\/p>/g, "").trim();
  html = html.replace(/<(\w+)\s*>\s*<\/\1>/g, "");
  html = html.replace(/^\s+|\s+$/g, "");
  return html;
};

// Function to truncate HTML content while preserving formatting
const truncateHtml = (htmlContent, wordLimit = 30) => {
  const doc = new DOMParser().parseFromString(htmlContent, "text/html");
  const body = doc.body;
  let textContent = "";

  // Helper function to extract text and HTML from nodes
  const extractText = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      textContent += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // Add opening tag
      textContent += `<${node.nodeName.toLowerCase()}`;
      for (let attr of node.attributes) {
        textContent += ` ${attr.name}="${attr.value}"`;
      }
      textContent += ">";
      // Recursively add child nodes
      node.childNodes.forEach(extractText);
      // Add closing tag
      textContent += `</${node.nodeName.toLowerCase()}>`;
    }
  };

  extractText(body);

  // Truncate the text content
  const words = textContent.split(/\s+/).filter(Boolean);
  if (words.length > wordLimit) {
    textContent = words.slice(0, wordLimit).join(" ") + "...";
  }

  // Create a new document with the truncated text content
  const truncatedDoc = new DOMParser().parseFromString(textContent, "text/html");
  return truncatedDoc.body.innerHTML;
};

// Function to convert title to a URL-friendly slug
const slugify = (title) => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, "") // Remove non-alphanumeric characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
};

function ArticlesPage() {
  const [articles, setArticles] = useState([]);
  const [users, setUsers] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    let initialFetch = true;

    const fetchArticlesAndUsers = async () => {
      if (initialFetch) {
        setLoading(true); // Set loading to true only for the initial fetch
      }

      try {
        const articlesData = await getArticles();
        const usersData = await getUsers();

        // Filter articles to only include those where isVisible is true
        const visibleArticles = articlesData.filter((article) => article.isVisible);

        // Create a mapping of user ID to user details
        const usersMap = usersData.reduce((acc, user) => {
          acc[user._id] = user;
          return acc;
        }, {});

        setUsers(usersMap);
        setArticles(visibleArticles);
      } catch (error) {
        console.error("Error fetching articles or users:", error);
      } finally {
        if (initialFetch) {
          setLoading(false); // Set loading to false only after the initial fetch
          initialFetch = false; // Ensure subsequent fetches don't trigger the loader
        }
      }
    };

    // Fetch immediately on mount and then every 3 seconds
    fetchArticlesAndUsers();
    const intervalId = setInterval(fetchArticlesAndUsers, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to filter articles based on search query
  const filteredArticles = articles.filter((article) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      article.title.toLowerCase().includes(searchLower) ||
      removeImagesFromHtml(article.content).toLowerCase().includes(searchLower) ||
      (users[article.author] &&
        users[article.author].username.toLowerCase().includes(searchLower)) ||
      new Date(article.createdAt).toLocaleDateString().includes(searchLower)
    );
  });

  const token = localStorage.getItem("token");
  const isTokenValid = token ? true : false;
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          isTokenValid
            ? undefined
            : {
                type: "internal",
                route: "/pages/authentication/register",
                label: "Join us",
                color: "success",
              }
        }
        action1={{
          type: "internal",
          route: "/pages/landing-pages/donate",
          label: "Donate",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Here, you can see all of our articles
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox pt={6} pb={3} px={3}>
          <Container>
            <Grid container item xs={12} lg={4} py={1} mx="auto" mb={5} sticky>
              <MKInput
                variant="standard"
                placeholder="Search Article..."
                fullWidth
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="medium" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Container>
          {loading ? ( // Conditional rendering based on loading state
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 9999,
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Grid container spacing={10}>
              {filteredArticles.map((article) => {
                // Debugging: Check the article data
                console.log("Processing article:", article);

                // Extract image URL and process content
                const imageUrl = extractImageUrl(article.content);
                const contentWithoutImages = removeImagesFromHtml(article.content);
                const truncatedDescription = truncateHtml(contentWithoutImages);

                // Generate URL-friendly slug from article title
                const slug = slugify(article.title);

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={article._id}
                    style={{ position: "relative" }}
                  >
                    <TransparentBlogCard
                      image={imageUrl}
                      title={article.title}
                      style={{
                        padding: "6px", // Add padding around the card
                        maxWidth: "100%", // Ensure the card doesn't exceed its container width
                        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Transition for the card
                        overflow: "hidden",
                        "&:hover": {
                          transform: "scale(1.05)", // Scale up the card on hover
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Add shadow effect
                        },
                      }}
                      description={
                        <div>
                          <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
                        </div>
                      }
                      action={{
                        type: "internal",
                        route: `/articles/${slug}`,
                        color: "success",
                        label: "Read More",
                      }}
                      imageStyle={{
                        width: "100%", // Let the image fill the width of its container
                        height: "auto", // Maintain aspect ratio
                        maxWidth: "300px", // Set a maximum width for the image
                        objectFit: "cover", // Ensure the image covers the container without distortion
                        margin: "auto", // Center the image within its container
                        transition: "transform 0.3s ease", // Transition for the image
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ArticlesPage;
