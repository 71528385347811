import React from "react";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import { Card, Grid } from "@mui/material";
import DefaultInfoCard from "components/Admin/Cards/InfoCards/DefaultInfoCard";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DefaultFooter from "components/User/Footers/DefaultFooter";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import routes from "routes";
import footerRoutes from "footer.routes";

const token = localStorage.getItem("token");
const isTokenValid = token ? true : false;

const MissionImage =
  "https://www.unicef.fr/wp-content/uploads/2022/07/groupe-enfants-tanzanie-unicef.jpg";

function Missions() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          isTokenValid
            ? undefined
            : {
                type: "internal",
                route: "/pages/authentication/register",
                label: "Join us",
                color: "success",
              }
        }
        action1={{
          type: "internal",
          route: "/pages/authentication/register",
          label: "Donate",
          color: "success",
        }}
        sticky
      />
      <MKBox
        px={2}
        width="100%"
        mx="auto"
        position="relative"
        zIndex={2}
        sx={{
          padding: { xs: 2, sm: 3, md: 4 },
          margin: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} mt="15vh">
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="md"
                coloredShadow="dark"
                mx={2}
                mt={-3}
                p={2}
                mb={3}
              >
                <MKTypography variant="h2" fontWeight="bold" color="white">
                  <span style={{ color: "#FF5206" }}>ELLE & LUI</span> - Missions
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    padding: 2,
                  }}
                >
                  <MKBox
                    component="img"
                    src={MissionImage}
                    alt="Mission"
                    width="100%"
                    maxWidth="600px"
                    borderRadius="md"
                    sx={{ mb: 2 }}
                  />
                  <MKTypography variant="h3" fontWeight="bold" color="text.primary" mb={2}>
                    <span style={{ color: "#048404" }}>Mission</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text.secondary" mb={3}>
                    Our association&apos;s mission is to <strong>promote leadership</strong> in
                    girls and <em>positive masculinity</em> in boys.
                  </MKTypography>
                  <Grid container spacing={5} justifyContent="center">
                    <Grid item xs={12} md={6}>
                      <MKBox mb={5}>
                        <DefaultInfoCard
                          icon="people"
                          title="Empowering Youth"
                          description="We are dedicated to the empowerment and development of young girls and boys, creating an environment where both genders can thrive."
                        />
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKBox mb={5}>
                        <DefaultInfoCard
                          icon="group"
                          title="Equality and Support"
                          description="Founded on values of equality, respect, mutual support, and solidarity, we strive to provide the tools and guidance necessary for personal and social development."
                        />
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKBox mb={5}>
                        <DefaultInfoCard
                          icon="star"
                          title="Leadership for Girls"
                          description="We offer dynamic programs to foster leadership in young girls, helping them assert themselves and become future leaders."
                        />
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKBox mb={5}>
                        <DefaultInfoCard
                          icon="male"
                          title="Positive Masculinity"
                          description="Our programs for boys promote positive masculinity, encouraging them to express emotions, develop empathy, and engage in creative and caring activities."
                        />
                      </MKBox>
                    </Grid>
                  </Grid>

                  <Link to="/home" style={{ textDecoration: "none" }}>
                    <MKBox
                      display="flex"
                      alignItems="center"
                      sx={{
                        mt: 5,
                        color: "text.primary",
                        "&:hover": {
                          color: "success.main",
                          "& .MuiSvgIcon-root": {
                            transform: "translateX(-7px)",
                          },
                        },
                        transition: "color 0.3s ease",
                      }}
                    >
                      <ArrowBackIcon
                        sx={{
                          fontSize: "3rem",
                          transition: "transform 0.3s ease",
                        }}
                      />
                      <MKTypography
                        variant="h5"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ ml: 1 }}
                      >
                        Return Home
                      </MKTypography>
                    </MKBox>
                  </Link>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>

      <DefaultFooter content={footerRoutes} />
    </>
  );
}

export default Missions;
