/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/

// @mui material components
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import { useInView } from "react-intersection-observer";

// ELLE ET LUI -EGALITE React components
import MKBox from "components/User/MKBox";
import MKButton from "components/User/MKButton";
import Values from "pages/User/Home/Components/Values";
import Team from "pages/User/Home/Components/Team";
import ContactUs from "pages/User/Home/Components/ContactUs";
import Blog from "pages/User/Home/Components/Blog";
import Resources from "pages/User/Home/Components/Resources";
import Involve from "pages/User/Home/Components/Involve";
import Programs from "pages/User/Home/Components/Programs";
import News from "pages/User/Home/Components/News";
import MKTypography from "components/User/MKTypography";
import DefaultInfoCard from "components/User/Cards/InfoCards/DefaultInfoCard";

// Image URLs
const MissionImage =
  "https://www.unicef.fr/wp-content/uploads/2022/07/groupe-enfants-tanzanie-unicef.jpg";

// ELLE ET LUI -EGALITE React examples
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import DefaultFooter from "components/User/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/User/images/bg-home.jpg";
import { Flag, Book } from "@mui/icons-material";

const token = localStorage.getItem("token");
const isTokenValid = token ? true : false;

const handleReadMore = () => {
  window.location.href = "/pages/landing-pages/about-us";
};

const handleGetStarted = () => {
  window.location.href = "#Welcome";
};

function Home() {
  const { ref: welcomeRef, inView: welcomeInView } = useInView({ triggerOnce: true });
  const { ref: teamRef, inView: teamInView } = useInView({ triggerOnce: true });
  const { ref: contactRef, inView: contactInView } = useInView({ triggerOnce: true });
  const { ref: blogRef, inView: blogInView } = useInView({ triggerOnce: true });
  const { ref: resourcesRef, inView: resourcesInView } = useInView({ triggerOnce: true });
  const { ref: involveRef, inView: involveInView } = useInView({ triggerOnce: true });
  const { ref: programsRef, inView: programsInView } = useInView({ triggerOnce: true });
  const { ref: newsRef, inView: newsInView } = useInView({ triggerOnce: true });

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          isTokenValid
            ? undefined
            : {
                type: "internal",
                route: "/pages/authentication/register",
                label: "Join us",
                color: "success",
              }
        }
        action1={{
          type: "internal",
          route: "/pages/landing-pages/donate",
          label: "Donate",
        }}
        sticky
      />

      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={15} lg={7} justifyContent="center" mx="auto">
            <Stack direction="row" spacing={1} mt={12}>
              <MKButton color="white" onClick={handleGetStarted} startIcon={<Flag />}>
                Get Started
              </MKButton>
              <MKButton variant="text" onClick={handleReadMore} color="black" startIcon={<Book />}>
                Read More
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>

      {/* À Propos Section */}
      <Grow in={welcomeInView} timeout={850}>
        <Card
          id="Welcome"
          style={{
            hyphens: "auto",
          }}
          sx={{
            p: 0.3,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          ref={welcomeRef}
        >
          <MKBox pt={6} pb={6}>
            <Container>
              <Grid item xs={12} lg={6}>
                <MKBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 5,
                    mt: 3,
                  }}
                >
                  <MKTypography
                    variant="h1"
                    fontWeight="bold"
                    sx={{
                      color: "#FF5206",
                    }}
                  >
                    Welcome to ELLE & LUI
                  </MKTypography>
                </MKBox>
                <MKTypography variant="body1" color="text" mt={2}>
                  Our association is dedicated to promoting <strong>leadership</strong> in girls and{" "}
                  <strong>positive masculinity</strong> in boys. We aim to create a more balanced
                  and supportive environment for all genders, encouraging growth, respect, and
                  mutual empowerment. Our mission is to empower youth by providing them with the
                  resources and guidance they need to thrive in today&apos;s world. We offer various
                  programs that cater to the unique needs of both girls and boys, ensuring they grow
                  up with the confidence and skills necessary to succeed.
                </MKTypography>
                <br />
                <MKBox
                  id="missions"
                  component="img"
                  src={MissionImage}
                  alt="Mission"
                  width="100%"
                  borderRadius="md"
                />
                <MKTypography variant="h3" fontWeight="bold" mt={3} mb={3}>
                  <span style={{ color: "#048404" }}>Mission</span>
                </MKTypography>
                <MKTypography variant="body1" color="text" mt={2}>
                  Our mission is to empower youth by providing them with the resources and guidance
                  they need to thrive in today&apos;s world. We offer various programs that cater to
                  the unique needs of both girls and boys, ensuring they grow up with the confidence
                  and skills necessary to succeed.
                </MKTypography>
              </Grid>
              <MKBox component="section" py={12}>
                <Container>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item>
                      <Grid container justifyContent="flex-start">
                        <Grid item xs={12} md={6}>
                          <MKBox mb={5}>
                            <DefaultInfoCard
                              icon="people"
                              title="Empowering Youth"
                              description="We are dedicated to the empowerment and development of young girls and boys, creating an environment where both genders can thrive."
                            />
                          </MKBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKBox mb={5}>
                            <DefaultInfoCard
                              icon="group"
                              title="Equality and Support"
                              description="Founded on values of equality, respect, mutual support, and solidarity, we strive to provide the tools and guidance necessary for personal and social development."
                            />
                          </MKBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKBox mb={{ xs: 5, md: 0 }}>
                            <DefaultInfoCard
                              icon="star"
                              title="Leadership for Girls"
                              description="We offer dynamic programs to foster leadership in young girls, helping them assert themselves and become future leaders."
                            />
                          </MKBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKBox mb={{ xs: 5, md: 0 }}>
                            <DefaultInfoCard
                              icon="male"
                              title="Positive Masculinity"
                              description="Our programs for boys promote positive masculinity, encouraging them to express emotions, develop empathy, and engage in creative and caring activities."
                            />
                          </MKBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </MKBox>
              <Values />
            </Container>
          </MKBox>
        </Card>
      </Grow>

      {/* Team Section */}
      <Grow in={teamInView} timeout={800}>
        <Card
          id="team"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          ref={teamRef}
        >
          <MKBox pt={6} pb={6}>
            <Container>
              <Team />
            </Container>
          </MKBox>
        </Card>
      </Grow>

      {/* Contact Us Section */}
      <Grow in={contactInView} timeout={800}>
        <Card
          id="contact"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          ref={contactRef}
        >
          <MKBox pt={6} pb={6}>
            <Container>
              <ContactUs />
            </Container>
          </MKBox>
        </Card>
      </Grow>

      {/* Blog Section */}
      <Grow in={blogInView} timeout={800}>
        <Card
          id="blog"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          ref={blogRef}
        >
          <MKBox pt={6} pb={6}>
            <Container>
              <Blog />
            </Container>
          </MKBox>
        </Card>
      </Grow>

      {/* Resources Section */}
      <Grow in={resourcesInView} timeout={800}>
        <Card
          id="resources"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          ref={resourcesRef}
        >
          <MKBox pt={6} pb={6}>
            <Container>
              <Resources />
            </Container>
          </MKBox>
        </Card>
      </Grow>

      {/* Involve Section */}
      <Grow in={involveInView} timeout={800}>
        <Card
          id="involve"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          ref={involveRef}
        >
          <MKBox pt={6} pb={6}>
            <Container>
              <Involve />
            </Container>
          </MKBox>
        </Card>
      </Grow>

      {/* Programs Section */}
      <Grow in={programsInView} timeout={800}>
        <Card
          id="programs"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          ref={programsRef}
        >
          <MKBox pt={6} pb={6}>
            <Container>
              <Programs />
            </Container>
          </MKBox>
        </Card>
      </Grow>

      {/* News Section */}
      <Grow in={newsInView} timeout={800}>
        <Card
          id="news"
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          ref={newsRef}
        >
          <MKBox pt={6} pb={6}>
            <Container>
              <News />
            </Container>
          </MKBox>
        </Card>
      </Grow>

      <DefaultFooter content={footerRoutes} />
    </>
  );
}

export default Home;
