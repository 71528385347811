/*
=========================================================
* ELLE ET LUI -EGALITE React 
=========================================================
Coded with love by CLaude Bernard NDOMANE (claudebernardndomane@gmail.com)
*/ import React, { useState } from "react";
import emailjs from "emailjs-com";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKBox from "components/User/MKBox";
import MKInput from "components/User/MKInput";
import MKButton from "components/User/MKButton";
import MKTypography from "components/User/MKTypography";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import DefaultFooter from "components/User/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/User/images/illustrations/illustration-reset.jpg";
import { useSnackbar } from "context/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box"; // Import Box from MUI

function ContactUs() {
  const { setSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.fullName || !formData.email || !formData.subject || !formData.message) {
      setSnackbar({
        open: true,
        message: "All fields must be filled!",
        color: "warning",
      });
      return;
    }

    setLoading(true); // Set loading to true when email sending starts

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      setSnackbar({
        open: true,
        message: "Email sent successfully!",
        color: "success",
      });
      setFormData({ fullName: "", email: "", subject: "", message: "" });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "There was an error sending the email.",
        color: "error",
      });
    } finally {
      setLoading(false); // Set loading to false when email sending finishes
    }
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="success" />
        </Box>
      )}
      <MKBox position="fixed" top="0.5rem" width="100%" sx={{ zIndex: 2 }}>
        <DefaultNavbar
          routes={routes}
          action1={{
            type: "internal",
            route: "/pages/authentication/register",
            label: "Donate",
            color: "success",
          }}
        />
      </MKBox>
      <MKBox component="section" py={{ xs: 0, lg: 6 }} mt={20}>
        <Container>
          <Grid container item>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.8),
                        rgba(gradients.dark.state, 0.8)
                      )}, url(${bgImage})`,
                    backgroundSize: "cover",
                  }}
                >
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                      <MKTypography variant="h3" color="white" mb={1}>
                        Contact Information
                      </MKTypography>
                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Fill up the form and our Team will get back to you within 24 hours.
                      </MKTypography>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="a"
                          href="tel:+237698956461"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          (+237) 698 956 461
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="a"
                          href="tel:+237652398906"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          (+237) 652 39 89 06
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>

                        <MKTypography
                          component="a"
                          href="maito:elleetlui.egalite@gmail.com"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          elleetlui.egalite@gmail.com
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>

                        <MKTypography
                          component="a"
                          href="maito:contact@elleetlui-egalite.com"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          contact@elleetlui-egalite.com
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-map-marker-alt" />
                        </MKTypography>
                        <MKTypography
                          component="a"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          Yaounde - center - Cameroon
                        </MKTypography>
                      </MKBox>
                      <MKBox mt={3}>
                        <MKButton
                          variant="a"
                          href="https://www.facebook.com/share/69XztKY6YHDfVDN7/?mibextid=qi2Omg"
                          size="large"
                          iconOnly
                        >
                          <i
                            className="fab fa-facebook"
                            style={{ fontSize: "1.25rem", color: "white" }}
                          />
                        </MKButton>

                        <MKButton
                          variant="a"
                          href="https://www.linkedin.com/company/association-elle-lui?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3Be1yZaJL7Rn%2BZgC2B6IXYEA%3D%3D"
                          size="large"
                          iconOnly
                        >
                          <i
                            className="fab fa-linkedin"
                            style={{ fontSize: "1.25rem", color: "white" }}
                          />
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <MKBox component="form" p={2} method="post" onSubmit={handleSubmit}>
                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                      <MKTypography variant="h2" mb={1}>
                        Say Hi!
                      </MKTypography>
                      <MKTypography variant="body1" color="text" mb={2}>
                        We&apos;d like to talk with you.
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={0.5} pb={3} px={3}>
                      <Grid container>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="My name is"
                            placeholder="Full Name"
                            InputLabelProps={{ shrink: true }}
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="My email is"
                            placeholder="Email Address"
                            InputLabelProps={{ shrink: true }}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="I'm looking for"
                            placeholder="What you love"
                            InputLabelProps={{ shrink: true }}
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={6}>
                          <MKInput
                            variant="standard"
                            label="Your message"
                            placeholder="I want to say that..."
                            InputLabelProps={{ shrink: true }}
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            rows={6}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-center"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="gradient" color="success" type="submit">
                          Send Message
                        </MKButton>
                      </Grid>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
