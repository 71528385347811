/**
=========================================================
* Project Name: Egalite ELLE ET LUI -EGALITE
=========================================================

* This web app uses React 

* Copyright 2024 NDOMANE CLAUDE BERNARD (https://github.com/Ndomane237)

Coded with love by CLAUDE BERNARD NDOMANE (Fullstack developer, NSE 3, system administrator... )

 =========================================================

* For ulterior questions or to order a website send an email to: claudebernardndomane@gmail.com

*/

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { Grid, Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import ContactIcon from "@mui/icons-material/Phone";
import SigninIcon from "@mui/icons-material/Login";

// ELLE ET LUI-EGALITE React components
import MDBox from "components/Admin/MDBox";
import MDTypography from "components/Admin/MDTypography";
import MDButton from "components/Admin/MDButton";
import MKBox from "components/User/MKBox";
import MKSocialButton from "components/User/MKSocialButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "components/Admin/Configurator/ConfiguratorRoot";

// ELLE ET LUI-EGALITE React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
} from "context";

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
  const configuratorRef = useRef(null);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const username = localStorage.getItem("username") || "Unknown";
  const email = localStorage.getItem("email") || "Unknown";
  const token = localStorage.getItem("token");
  useEffect(() => {
    // Check if role is admin and update state
    const role = localStorage.getItem("role");
    if (role === "admin") {
      setIsAdmin(true);
    }
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => {
    setTransparentSidenav(dispatch, true);
    setWhiteSidenav(dispatch, false);
  };
  const handleWhiteSidenav = () => {
    setWhiteSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
  };
  const handleDarkSidenav = () => {
    setWhiteSidenav(dispatch, false);
    setTransparentSidenav(dispatch, false);
  };
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configuratorRef.current && !configuratorRef.current.contains(event.target)) {
        handleCloseConfigurator();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleGoToDashboard = () => {
    navigate("/dashboard");
    handleCloseConfigurator();
  };

  const handleLogout = () => {
    navigate("/logout");
    handleCloseConfigurator();
  };

  const handleSignin = () => {
    navigate("/pages/authentication/sign-in");
    handleCloseConfigurator();
  };

  const handleGoToHome = () => {
    navigate("/home");
    handleCloseConfigurator();
  };

  const handleContact = () => {
    navigate("/pages/landing-pages/contact-us");
    handleCloseConfigurator();
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} ref={configuratorRef}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Elle et lui Configurator</MDTypography>
          <MDTypography variant="body2" color="text">
            Change your settings here
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          lineHeight={1}
        >
          <MDTypography variant="h6">Navbar Fixed</MDTypography>

          <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Light / Dark</MDTypography>

          <Switch checked={darkMode} onChange={handleDarkMode} />
        </MDBox>
        <Divider />
        <MDTypography variant="h4" gutterBottom style={{ textAlign: "center", fontWeight: "bold" }}>
          General User Information
        </MDTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4} md={3} lg={2} style={{ display: "flex", alignItems: "center" }}>
            <Avatar style={{ backgroundColor: "#FF5206", marginRight: "8px" }}>
              <PersonIcon />
            </Avatar>
            <MDTypography
              variant="h6"
              component="div"
              style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              <strong>Username:</strong> {username}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          <Grid item xs={12} sm={4} md={3} lg={2} style={{ display: "flex", alignItems: "center" }}>
            <Avatar style={{ backgroundColor: "#FF5206", marginRight: "8px" }}>
              <EmailIcon />
            </Avatar>
            <MDTypography
              variant="h6"
              component="div"
              style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              <strong>Email:</strong> {email}
            </MDTypography>
          </Grid>

          {token ? (
            <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
              <MDBox mt={4} mb={2}>
                <MDButton
                  onClick={handleLogout}
                  variant="outlined"
                  color="error"
                  startIcon={<LogoutIcon />}
                  fullWidth
                >
                  logout
                </MDButton>
              </MDBox>
            </Grid>
          ) : (
            <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
              <MDBox mt={4} mb={2}>
                <MDButton
                  onClick={handleSignin}
                  variant="outlined"
                  color="success"
                  startIcon={<SigninIcon />}
                  fullWidth
                >
                  Sign in
                </MDButton>
              </MDBox>
            </Grid>
          )}
          <Divider />
        </Grid>
        {isAdmin ? (
          <MDBox mt={3} mb={2}>
            <MDBox>
              <MDTypography variant="h6">Sidenav Colors</MDTypography>

              <MDBox mb={0.5}>
                {sidenavColors.map((color) => (
                  <IconButton
                    key={color}
                    sx={({
                      borders: { borderWidth },
                      palette: { white, dark, background },
                      transitions,
                    }) => ({
                      width: "24px",
                      height: "24px",
                      padding: 0,
                      border: `${borderWidth[1]} solid ${darkMode ? background.sidenav : white.main}`,
                      borderColor: () => {
                        let borderColorValue = sidenavColor === color && dark.main;

                        if (darkMode && sidenavColor === color) {
                          borderColorValue = white.main;
                        }

                        return borderColorValue;
                      },
                      transition: transitions.create("border-color", {
                        easing: transitions.easing.sharp,
                        duration: transitions.duration.shorter,
                      }),
                      backgroundImage: ({
                        functions: { linearGradient },
                        palette: { gradients },
                      }) => linearGradient(gradients[color].main, gradients[color].state),

                      "&:not(:last-child)": {
                        mr: 1,
                      },

                      "&:hover, &:focus, &:active": {
                        borderColor: darkMode ? white.main : dark.main,
                      },
                    })}
                    onClick={() => setSidenavColor(dispatch, color)}
                  />
                ))}
              </MDBox>
            </MDBox>

            <MDBox mt={3} lineHeight={1}>
              <MDTypography variant="h6">Sidenav Type</MDTypography>
              <MDTypography variant="button" color="text">
                Choose between different sidenav types.
              </MDTypography>

              <MDBox
                sx={{
                  display: "flex",
                  mt: 2,
                  mr: 1,
                  mb: 2,
                }}
              >
                <MDButton
                  color="dark"
                  variant="gradient"
                  onClick={handleDarkSidenav}
                  fullWidth
                  sx={
                    !transparentSidenav && !whiteSidenav
                      ? sidenavTypeActiveButtonStyles
                      : sidenavTypeButtonsStyles
                  }
                >
                  Dark
                </MDButton>
                <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleTransparentSidenav}
                    fullWidth
                    sx={
                      transparentSidenav && !whiteSidenav
                        ? sidenavTypeActiveButtonStyles
                        : sidenavTypeButtonsStyles
                    }
                  >
                    Transparent
                  </MDButton>
                </MDBox>
                <MDButton
                  color="dark"
                  variant="gradient"
                  onClick={handleWhiteSidenav}
                  fullWidth
                  sx={
                    whiteSidenav && !transparentSidenav
                      ? sidenavTypeActiveButtonStyles
                      : sidenavTypeButtonsStyles
                  }
                >
                  White
                </MDButton>
              </MDBox>
            </MDBox>
            <MDButton
              onClick={handleGoToDashboard}
              color={darkMode ? "light" : "black"}
              variant="outlined"
              fullWidth
            >
              Go to Dashboard
            </MDButton>
          </MDBox>
        ) : (
          <MDBox mt={3} mb={2}>
            <MDButton
              onClick={handleGoToHome}
              color={darkMode ? "light" : "black"}
              variant="outlined"
              startIcon={<HomeIcon />}
              fullWidth
            >
              Return Home
            </MDButton>
          </MDBox>
        )}
        <MDBox
          mt={3}
          mb={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <MDBox mb={2}>
            <MDButton
              onClick={handleContact}
              variant="outlined"
              color="success"
              startIcon={<ContactIcon />}
              sx={{
                textTransform: "none",
                borderRadius: "8px",
                padding: "10px 20px",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "rgba(0, 255, 0, 0.1)", // Light green hover effect
                },
              }}
            >
              Contact us
            </MDButton>
          </MDBox>
          <MDBox mb={2} display="flex" flexDirection="column" alignItems="center" gap={1}>
            <MDTypography variant="h6" fontWeight="600">
              Follow us on:
            </MDTypography>
            <MKBox display="flex" gap={1}>
              <MKSocialButton
                color="facebook"
                iconOnly
                href="https://www.facebook.com/share/69XztKY6YHDfVDN7/?mibextid=qi2Omg"
                sx={{
                  "&:hover": {
                    backgroundColor: "#3b5998",
                    color: "#fff",
                  },
                }}
              >
                <MKBox component="i" color="inherit" className="fab fa-facebook" />
              </MKSocialButton>
              <MKSocialButton
                color="linkedin"
                iconOnly
                href="https://www.linkedin.com/company/association-elle-lui?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3Be1yZaJL7Rn%2BZgC2B6IXYEA%3D%3D"
                sx={{
                  "&:hover": {
                    backgroundColor: "#1da1f2",
                    color: "#fff",
                  },
                }}
              >
                <MKBox component="i" color="inherit" className="fab fa-linkedin" />
              </MKSocialButton>
            </MKBox>
          </MDBox>
        </MDBox>
        <MDBox bottom={0} textAlign="center">
          <MDBox mb={0.5}>
            <MDTypography variant="h6">Thank you for your visit</MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
